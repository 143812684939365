<template>
  <div class="layoutDefault" style="margin-top: 2rem">
    <div class="grid grid-cols-1 gap-4">
      <div class="z-10">
        <div
          class="flex cursor-pointer rounded-lg py-2"
          style="max-width: 200px"
          @click="$router.go(-1)"
        >
          <IconArrow
            class="mr-3 mt-1 stroke-current text-habu h-6 w-6 stroke-2 transform rotate-0"
          />
          <span class="text-2xl text-normal">Open Ticket</span>
        </div>
      </div>
      <!-- ... -->
      <div class="z-10" v-if="loadData">
        <!-- <div class="card-ticket-form rounded-11px mb-2">
          <div class="text-xl font-bold text-htext py-2 text-center px-5">Select Ticket Type</div>
        </div> -->

        <div class="grid sm:grid-cols-7 gap-4 my-4">
          <div class="card-ticket-form rounded-11px sm:col-span-3 flex flex-col p-4">
            <div class="text-center font-semibold">Ticket type</div>
            <div class="text-center text-hgray8 text-sm font-light">
              Choose the type of ticket you need and make sure you select the correct type.
            </div>

            <div class="ot-type mt-5 p-4 flex flex-col">
              <div
                class="flex items-center justify-between space-x-3 cursor-pointer"
                @click="setCollapse(1)"
              >
                <div class="text-md text-htext font-semibold">
                  {{ ticketTypes.technical ? ticketTypes.technical[0].group : '' }}
                </div>
                <IconArrowRound
                  class="h-12 w-12"
                  :class="
                    tt.includes(collapseTicket) ? 'transform rotate-0' : 'transform rotate-180'
                  "
                />
              </div>
              <div class="text-sm text-hgray8 mt-2">
                Ticket yang berhubungan dengan <br />
                permintaan atau kerusakan teknis alat ...
                <br />

                <button
                  @click="MODAL_INFO('technical')"
                  class="text-xs text-hservice border border-hservice rounded py-0.5 px-1 mt-2"
                >
                  Read more
                </button>
              </div>

              <div
                class="flex flex-col"
                :class="tt.includes(collapseTicket) ? 'collapsable-max' : 'collapsable'"
              >
                <div class="border border-dashed border-black my-4"></div>
                <div
                  class="flex group mb-4 items-center space-x-4 py-3 px-4 rounded-10px cursor-pointer"
                  v-for="m in ticketTypes.technical"
                  :key="m.id"
                  @click="toPage(m)"
                  :class="$route.params.id == m.id ? 'bg-hblue' : 'bg-hblue9 hover:bg-hdarkblue '"
                >
                  <TicketRequest
                    v-if="m.id == 2"
                    class="h-5 w-5 fill-current"
                    :class="
                      $route.params.id == m.id ? 'text-white' : 'text-hblue group-hover:text-white'
                    "
                  />
                  <TicketTechnical
                    v-if="m.id == 1"
                    class="h-4 w-4 fill-current"
                    :class="
                      $route.params.id == m.id
                        ? ' text-white'
                        : ' text-hblue group-hover:text-white'
                    "
                  />

                  <span
                    class="text-sm font-semibold"
                    :class="
                      $route.params.id == m.id ? 'text-white' : 'text-htext group-hover:text-white '
                    "
                    >{{ m.alias }}</span
                  >
                </div>
              </div>
            </div>

            <div class="ot-type mt-5 p-4 flex flex-col">
              <div
                class="flex items-center justify-between space-x-3 cursor-pointer"
                @click="setCollapse(3)"
              >
                <div class="text-md text-htext font-semibold">
                  {{ ticketTypes.nontechnical ? ticketTypes.nontechnical[0].group : '' }}
                </div>
                <IconArrowRound
                  class="h-12 w-12"
                  :class="
                    nt.includes(collapseTicket) ? 'transform rotate-0' : 'transform rotate-180'
                  "
                />
              </div>
              <div class="text-sm text-hgray8 mt-2">
                Ticket yang berhubungan dengan <br />
                komplain atau permintaan non teknis ...
                <br />

                <button
                  @click="MODAL_INFO('nontechnical')"
                  class="text-xs text-hservice border border-hservice rounded py-0.5 px-1 mt-2"
                >
                  Read more
                </button>
              </div>

              <div
                class="flex flex-col"
                :class="nt.includes(collapseTicket) ? 'collapsable-max' : 'collapsable'"
              >
                <div class="border border-dashed border-black my-4"></div>
                <div
                  class="flex group mb-4 items-center space-x-4 py-3 px-4 rounded-10px cursor-pointer"
                  v-for="m in ticketTypes.nontechnical"
                  :key="m.id"
                  @click="toPage(m)"
                  :class="$route.params.id == m.id ? 'bg-hblue' : 'bg-hblue9 hover:bg-hdarkblue'"
                >
                  <TicketNTRequest
                    v-if="m.id == 4"
                    class="h-5 w-5 fill-current"
                    :class="
                      $route.params.id == m.id
                        ? 'text-white'
                        : 'text-horange1 group-hover:text-white'
                    "
                  />
                  <TicketNTComplaint
                    v-if="m.id == 3"
                    class="h-4 w-4 fill-current"
                    :class="
                      $route.params.id == m.id
                        ? ' text-white'
                        : ' text-hblue group-hover:text-white'
                    "
                  />

                  <span
                    class="text-sm font-semibold"
                    :class="
                      $route.params.id == m.id ? 'text-white' : 'text-htext group-hover:text-white'
                    "
                    >{{ m.alias }}</span
                  >
                </div>
              </div>
            </div>
          </div>

          <router-view class="card-ticket-form rounded-11px sm:col-span-4" />
        </div>
      </div>
      <div class="z-10" v-else>
        <div class="card-ticket-form animate-pulse">
          <div class="text-2xl text-medium pt-3 pb-0 px-5 text-black">
            <div class="h-7 bg-hline rounded w-68 mt-3"></div>
          </div>

          <div class="grid grid-cols-3 gap-4" style="width: 100%">
            <div
              class="group relative mt-3 pt-1 pb-0.5 px-5 text-center z-10 cursor-pointer hover:bg-habu2"
              v-for="m in 3"
              :key="m"
            >
              <div class="h-9 bg-hline rounded"></div>
            </div>
          </div>
          <div class="card-ticket-form-content mt-2 px-4 pt-8 pb-5">
            <div class="open-technical">
              <div class="grid xs:grid-cols-1 sm:grid-cols-12 gap-2">
                <div class="xs:col-span-12 sm:col-span-4">
                  <div class="mx-auto text-center" style="max-width: 180px">
                    <div class="text-2xl text-black">
                      <div class="h-10 bg-hline rounded"></div>
                    </div>

                    <div
                      class="mt-4 pt-3 mx-auto relative cursor-pointer hover:bg-habu2"
                      v-for="d in 5"
                      :key="d"
                    >
                      <div class="h-9 bg-hline rounded"></div>
                    </div>
                  </div>
                </div>
                <div class="col-span-1 xs:invisible sm:visible pt-18">
                  <div
                    class="mx-auto rounded-lg"
                    style="border: 4px solid #eeeeee; min-height: 375px; width: 0px"
                  ></div>
                </div>
                <div class="xs:col-span-12 sm:col-span-7" style="width: 400px; margin: 0 auto">
                  <div class="text-2xl text-black mb-7">
                    <div class="h-9 bg-hline rounded-lg"></div>
                  </div>

                  <div class="form-control mb-5">
                    <div class="h-16 bg-hline rounded-lg"></div>
                  </div>
                  <div class="form-control mb-5">
                    <div class="h-8 bg-hline rounded"></div>
                  </div>
                  <div class="form-control mb-5">
                    <div class="h-8 bg-hline rounded"></div>
                  </div>

                  <div
                    style="width: 100%; height: 14px; border-bottom: 2px solid #363636"
                    class="text-center mb-10"
                  >
                    <div class="h-8 bg-hline rounded"></div>
                  </div>

                  <div class="grid grid-cols-3 gap-2">
                    <div v-for="p in 3" :key="p">
                      <div class="h-8 bg-hline rounded"></div>
                    </div>
                  </div>

                  <div class="mt-8 mb-4 text-center w-full">
                    <div class="h-10 bg-hline rounded mx-auto"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrow from '@/assets/icons/icon-arrow.svg?inline'

import TicketTechnical from '@/assets/icons/icon-tc.svg?inline'
import TicketRequest from '@/assets/icons/icon-tr.svg?inline'
import TicketNTComplaint from '@/assets/icons/icon-ntc.svg?inline'
import TicketNTRequest from '@/assets/icons/icon-ntr.svg?inline'
import IconArrowRound from '@/assets/icons/icon-arrow-round.svg?inline'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ticket-open',
  components: {
    IconArrow,

    TicketNTComplaint,
    TicketNTRequest,
    TicketRequest,
    TicketTechnical,
    IconArrowRound
  },
  data() {
    return {
      loadData: false,
      collapseTicket: null,
      tt: [1, 2],
      nt: [3, 4]
    }
  },

  computed: {
    ...mapGetters('ticket', ['ticketTypes'])
  },
  mounted() {
    let route = this.$route.params
    if (route && route.id) this.collapseTicket = parseInt(route.id)
    this.getData()
  },
  methods: {
    ...mapActions('ticket', ['GET_DIVISIONS']),
    ...mapActions('modal', ['MODAL_TICKET_TYPE', 'MODAL_INFO']),
    toPage(val) {
      let sourceId =
        this.$route.query && this.$route.query.sourceId ? this.$route.query.sourceId : null

      if (val.name !== this.$route.name)
        this.$router.replace({
          name: val.to,
          params: { id: val.id },
          query: { sourceId: sourceId }
        })
    },
    async getData() {
      await this.GET_DIVISIONS()

      setTimeout(() => (this.loadData = true), 1000)
    },
    setCollapse(val) {
      this.collapseTicket = val
    }
  }
}
</script>
